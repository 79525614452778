<template>
    <div>
        <AppPaginatedGridNested
            :service="service"
            :parentName="parentName"
            :defaultProps="queryParams"
            :queryParams="queryParams"
            :expander="false"
            :showButtonAlterar="true"
            :defaultEdit="false"
            :labelBotaoAdicionarItem="labelBotaoAdicionarItem"
            @onEditGrid="onEditGrid"
        >
            <template #header>
                <h5>Perigo/Fator de Risco</h5>
            </template>
            <template #columns>
                <Column field="risco" header="Perigo/Fator de Risco">
                    <template #editor="{ data, field }">
                        <DropdownRiscos v-model="data[field]" @update:modelValue="data.riscoId = data[field]?.id" />
                    </template>
                    <template #body="slotProps">
                        {{ slotProps?.data?.risco?.nome }}
                    </template>
                </Column>
            </template>
        </AppPaginatedGridNested>
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div class="formatarTamanho flex flex-column md:flex-row md:justify-content-end md:ml-auto">
                <Button class="p-button p-button-outlined" icon="pi pi-arrow-left" label="Voltar" @click="this.$router.push({ path: 'list' })" />
            </div>
        </div>
    </div>
</template>

<script>
import DropdownRiscos from '../../riscos/components/DropdownRiscos.vue';

export default {
    emits: ['onEditGrid'],
    props: {
        queryParams: {
            type: Object
        },
        service: {
            type: Object,
            required: true
        },
        backPath: {
            type: String
        },
        parentName: {
            type: String
        },
        labelBotaoAdicionarItem: {
            type: String
        }
    },
    data() {
        return {};
    },
    components: {
        DropdownRiscos
    },
    methods: {
        onEditGrid(data) {
            this.$router.push(`/risco-procedimento/${data.id}`);
        }
    }
};
</script>
